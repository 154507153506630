var Highcharts = require("highcharts");

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/stock")(Highcharts);
require("highcharts/modules/annotations")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/navigator")(Highcharts);

getChartsData()

const containerId = 'js-chart-container'
const container = document.getElementById(containerId)

Highcharts.setOptions({
    global:{
        useUTC: false
    },
    lang: {
        months: ["styczeń", "luty", "marzec", "kwiecień", "maj", "czerwiec", "lipiec", "sierpień", "wrzesień", "październik", "listopad", "grudzień"],
        shortMonths: ["sty", "lut", "mar", "kwi", "maj", "cze", "lip", "sie", "wrz", "paź", "lis", "gru"],
        weekdays: ["niedziela", "poniedziałek", "wtorek", "środa", "czwartek", "piątek", "sobota"],
        rangeSelectorFrom: "Od:",
        rangeSelectorTo: "Do:",
        rangeSelectorZoom: "",
        decimalPoint: ",",
        thousandsSep: " ",
        noData: "Brak transakcji w tym okresie",
        downloadJPEG: "Pobierz jpg",
        downloadPDF: "Pobierz pdf",
        downloadPNG: "pobierz png",
        downloadSVG: "Pobierz svg",
        loading: "Ładuję dane...",
        printChart: "Drukuj wykres"
    }
});

async function getChartsData() {
    const request = await fetch(
        `${home_url}/wp-json/mda-rest-api-frontend/charts-data/get`,
        {
            method: 'POST',
        }
    );
    const data = await request.json();
    
    if (data) {
        var chart = Highcharts.stockChart(containerId, {
            rangeSelector: {
                enabled: false,
            },
            plotOptions:{
                series:{
                    pointStart: Date.UTC(2022, 11, 29),
                    pointInterval: 24 * 3600 * 1000
                }
            },
            xAxis: {
                gridLineWidth: 1,
                gridZIndex: 1,
                tickColor: '#d81f27',
                tickLength: 12,
                tickWidth: 6,
                tickPosition: 'inside'
            },
            yAxis: {
                gridLineWidth: 1,
                labels: {
                    step: 1
                },
            min: 0,
            tickInterval: 5
            },
            navigation: {
                bindings: {},
            },
            scrollbar: {
                enabled: false,
            },
            navigator: {
	            maskFill: 'rgba(216, 31, 39, 0.36)'
            },
            exporting: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            tooltip: {
                useHTML: true,
                formatter: function() {

                    var this_point_date = Highcharts.dateFormat('%A, %d.%m.%Y', this.points[0].key); //http://php.net/manual/en/function.strftime.php

                    var main = this.y; //kurs
                    num = main.toString(); //If it's not already a String
                    num = num.slice(0, (num.indexOf(".")) + 3); //With 3 exposing the hundredths place
                    if (num.length == 1) {
                        num = num + '.00'
                    }
                    if (num.length == 3) {
                        num = num + '0'
                    }
                    var this_point_index = this.points[0].point.index;
                    var volume = data[this_point_index][2]; //wolumen

                    if (volume == undefined) {}

                    //tooltip template
                    var html_code = '<table>' +
                        '<tr>' +
                        '<td><strong>' + this_point_date + '</strong></td>' + '</tr><tr>' +
                        '<td>Kurs: <strong>' + num + ' zł</strong></td>' + '</tr>';

                    if(volume > 0){
                        html_code += '<tr><td>Wolumen: <strong>' + volume + ' szt.</strong></td></tr>';
                    }

                    html_code +=  '</table>';

                    return html_code;
                }
            },
            series: [
                {
                    lineColor: "#ff000f",
                    lineWidth: 2,
                    color: "#31333a",
                    visible: true,
                    type: "area",
                    name: "Dane",
                    data: data,
                    gapSize: 5,
                    tooltip: {
                        valueDecimals: 2,
                    },
                    fillColor: {
                        linearGradient: [0, 0, 0, 300],
                        stops: [
                            [0, 'rgba(235, 183, 0, 0)'],
                            [1, 'rgba(235, 183, 0, 0)'],
                        ],
                    },
                    threshold: null,
                    showInNavigator: true
                },
            ],
            rangeSelector: {
                label: 'Test',
                buttonTheme: {
                    fill: 'none',
                    stroke: 'none',
                    'stroke-width': 0,
                    width: '20%',
                    r: 0,
                    style: {
                        color: '#d81f27',
                        padding: '10px',
                        fontWeight: 'bold',
                    },
                    states: {
                        hover: {
                            fill: '#d81f27',
                            style: {
                                color: 'white'
                            }
                        },
                        select: {
                            fill: '#d81f27',
                            style: {
                                color: 'white'
                            }
                        }
                    }
                },
                buttons: [{
                    type: 'week',
                    count: 1,
                    text: 'Tydzień',
                    events: {
                        click: function() {
                            getTableData('week')
                        }
                    }
                }, {
                    type: 'month',
                    count: 1,
                    text: 'Miesiąc',
                    events: {
                        click: function() {
                            getTableData('month')
                        }
                    }
                }, {
                    type: 'month',
                    count: 3,
                    text: 'Kwartał',
                    events: {
                        click: function() {
                            getTableData('quarter')
                        }
                    }
                },
                {
                    type: 'year',
                    count: 1,
                    text: 'Rok',
                    events: {
                        click: function() {
                            getTableData('year')
                        }
                    }
                }, {
                    type: 'all',
                    text: 'Od debiutu',
                    events: {
                        click: function() {
                            getTableData('from_begining')
                        }
                    }
                }],
                selected: 1,
                inputEnabled: false,
            },
        });
    }
}

const tableId = 'js-chart-table'
const table = document.getElementById(tableId)

if (table) {
    getTableData()
}

async function getTableData(period = 'month') {
    const formData = new FormData();
    formData.append('period', period);

    const request = await fetch(
        `${home_url}/wp-json/mda-rest-api-frontend/charts-data/table`,
        {
            method: 'POST',
            body: formData,
        }
    );
    const data = await request.json();

    if (data) {
        $('#kurs').html(data.stock_rate);
        $('#zmiana_procentowa').html(data.compare + '%');
        $('#kurs_odniesienia').html(data.reference_rate);
        $('#wolumen').html(data.volumen_count);
        $('#kurs_minimalny').html(data.min_rate);
        $('#kurs_maksymalny').html(data.max_rate);
        $('#obrot').html(data.turnover + ' zł');
    }
}

// $.getJSON(mda_config.investor_base_url+'/api/records/table?period=month', function(data) {
//     putData(data.data);
// });

// $(document).on('click', '.highcharts-button', function(){
//     var text = $(this).find('text').html();


//     if(text == 'Tydzień') {
//         var text = 'week';
//     }else if(text == 'Miesiąc') {
//         var text = 'month';
//     }else if(text == 'Kwartał') {
//         var text = 'quarter';
//     }else if(text == 'Rok') {
//         var text = 'year';
//     }else {
//         var text = 'from_begining';
//     }

//     $.getJSON(mda_config.investor_base_url+'/api/records/table?period='+text, function(data) {
//         putData(data.data);
//     });
// });

// function putData(data) {
//     $('#kurs').html(data.kurs_teraz);
//     $('#zmiana_procentowa').html(data.zmiana_procentowa+'%');
//     $('#kurs_odniesienia').html(data.kurs_odniesienia);
//     $('#wolumen').html(data.wolumen);
//     $('#kurs_minimalny').html(data.kurs_minimalny);
//     $('#kurs_maksymalny').html(data.kurs_maksymalny);
//     $('#obrot').html(data.obrot+' zł');
// }